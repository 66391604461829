<template>
  <div>
    <ContentHeader :class="layout.desktop + ' mb-privacy-content-header mb-simple-header'"
                   :title="$t('safeometer_privacy')"
                   :fsize="`35px`"
                   :line-height="`45px`"
    />

    <ContentHeader :class="layout.mobile + ' mb-privacy-content-header mb-simple-header'"
                   :title="$t('safeometer_privacy')"
                   :fsize="`28px`"
                   :line-height="`36px`"
    />
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="mb-privacy-content-desc" v-html="$t('safeometer_privacy_description')"></div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ContentHeader from "@/components/ContentHeader";
import { BContainer } from 'bootstrap-vue';
import { BRow } from 'bootstrap-vue';
import { BCol } from 'bootstrap-vue';

export default {
name: "Privacy",
  components:{
    ContentHeader,
    'b-container': BContainer,
    'b-row': BRow,
    'b-col': BCol
  },
  mounted() {
    window.scrollTo(0,0);
  },
  data(){
    return {
      layout: this.$responsiveLayout
    }
  }
}
</script>

<style scoped>
.mb-privacy-content-desc {
  text-align: left;
  margin-top: 1em;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
</style>